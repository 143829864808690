<template>
  <div>
    <v-row>
      <!--      <v-col-->
      <!--        cols="12"-->
      <!--        md="12">-->
      <!--        <teller-dashboard-funds-card-->
      <!--          :fund_amount="fund_amount"-->
      <!--          :cash_on_hand="cash_on_hand"-->
      <!--          :total_deposits="total_deposits"-->
      <!--          :total_withdrews="total_withdrews"-->
      <!--          :teller_fund_data="teller_fund_data"-->
      <!--        ></teller-dashboard-funds-card>-->
      <!--      </v-col>-->
      <v-col
        cols="12"
        md="12"
      >
        <teller-dashboard-card-deposit-and-withdraw-view
          :transaction_of_cashier="transaction_of_cashier"
          :gcash_transaction_of_cashier="gcash_transaction_of_cashier"
          :bank_transaction_of_cashier="bank_transaction_of_cashier"
          :total_cash="total_cash"
          :total_gcash="total_gcash"
          :total_bank="total_bank"
        ></teller-dashboard-card-deposit-and-withdraw-view>
      </v-col>
    </v-row>
    <v-dialog v-model="is_change_password" persistent max-width="50%">
      <ChangePassword></ChangePassword>
    </v-dialog>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline} from '@mdi/js'

  // demos

  import TellerDashboardFundsCard from './cashier_interface/TellerDashboardFundsCard'
  import TellerDashboardCardDepositAndWithdrawView
    from './cashier_interface/TellerCardDepositAndWithdrawBranch'
  import ChangePassword from "@/views/dashboard/ChangePassword";
  import {mapActions, mapGetters} from "vuex";

  export default {
    components: {
      TellerDashboardFundsCard,
      TellerDashboardCardDepositAndWithdrawView,
      ChangePassword,
    },
    computed: {
      ...mapGetters('authentication', ['branch_id_selected', 'branch_id', 'position', 'user_id', 'month_of', 'need_change_password']),
    },
    data() {
      return {
        is_change_password: false,
        data_table_loading: false,
        deposit: [],
        transaction_of_cashier: [],
        gcash_transaction_of_cashier: [],
        bank_transaction_of_cashier: [],
        totalEarnings: '0',
        total_cash: 'Total: 0.00',
        total_gcash: 'Total: 0.00',
        total_bank: 'Total: 0.00',
        totalEarning: [],
        teller_fund_data: [],
        listOfSales: [],
        total_deposits: '0',
        total_withdrews: '0',
        fund_amount: '0',
        cash_on_hand: '0',
      }
    },
    methods: {
      ...mapActions('purchase_order_slip', ['get_daily_cashiers_transaction',]),
    },
    mounted() {
      this.is_change_password = this.need_change_password
      this.data_table_loading = true
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const data = new FormData()
        data.append('branch_id', this.position === 'CASHIER' ? this.branch_id : this.branch_id_selected);
        data.append('cashier_id', this.position === 'CASHIER' ? this.user_id : '');
        this.get_daily_cashiers_transaction(data)
          .then(response => {
            this.transaction_of_cashier = response.data[0].cash
            this.gcash_transaction_of_cashier = response.data[0].gcash
            this.bank_transaction_of_cashier = response.data[0].bank
            this.total_cash = response.data[0].total_cash
            this.total_gcash = response.data[0].total_gcash
            this.total_bank = response.data[0].total_bank
            this.data_table_loading = false
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
            this.data_table_loading = false
          })
      }, 500)
    },
    setup() {
      const totalProfit = {
        statTitle: 'Total Profit',
        icon: mdiPoll,
        color: 'success',
        subtitle: 'Weekly Project',
        statistics: '$25.6k',
        change: '+42%',
      }

      const totalSales = {
        statTitle: 'Refunds',
        icon: mdiCurrencyUsd,
        color: 'secondary',
        subtitle: 'Past Month',
        statistics: '$78',
        change: '-15%',
      }

      // vertical card options
      const newProject = {
        statTitle: 'New Project',
        icon: mdiLabelVariantOutline,
        color: 'primary',
        subtitle: 'Yearly Project',
        statistics: '862',
        change: '-18%',
      }

      const salesQueries = {
        statTitle: 'Sales Quries',
        icon: mdiHelpCircleOutline,
        color: 'warning',
        subtitle: 'Last week',
        statistics: '15',
        change: '-18%',
      }

      return {
        totalProfit,
        totalSales,
        newProject,
        salesQueries,
      }
    },
  }
</script>
